
import { defineComponent } from "vue";
import KycVerificationInfomation from "@/components/page/kyc/kyc-verification/Information.vue";

export default defineComponent({
  name: "kyc-verification-legal-entity-information",
  components: {
    KycVerificationInfomation,
  },
  setup() {
    return {};
  },
});
